import styled from "styled-components/macro";
import { COLORS, QUERIES, WEIGHTS } from "../../../../constants";

const MainStory = ({ story }) => {
  return (
    <MainStoryGrid>
      <Headline>{story.headline}</Headline>
      <Summary>{story.summary}</Summary>
      <Image src={`/images/${story.imageName}`} />
      <Content>{story.content}</Content>
    </MainStoryGrid>
  );
};

const MainStoryGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "headline headline"
    "image image"
    "summary summary"
    "content content";
  row-gap: 16px;

  /* position: relative;
  top: -64px; */
`;

const Headline = styled.h2`
  grid-area: headline;
  font-size: 1.75rem;
  line-height: 1.2;
  color: ${COLORS.text};
  max-width: 50ch;
  user-select: none;

  @media ${QUERIES.tabletAndDown} {
    font-size: 1.75rem;
  }
`;
const Summary = styled.div`
  grid-area: summary;
  font-size: 1.25rem;
  color: ${COLORS.text};
  max-width: 60ch;
  user-select: none;
  /* border-left: 2px solid ${COLORS.primary};
  padding-left: 16px; */

  @media ${QUERIES.tabletAndDown} {
    font-size: 1rem;
    font-weight: ${WEIGHTS.bold};
  }
`;
const Content = styled.p`
  grid-area: content;
  font-size: 1rem;
  color: ${COLORS.text};
  max-width: 70ch;
  white-space: pre-line;
  user-select: none;
`;
const Image = styled.img`
  grid-area: image;
  width: 100%;
`;

export default MainStory;

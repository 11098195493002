import styled from "styled-components/macro";
import { COLORS, QUERIES } from "../../constants";

const Glow = ({reverse = false}) => {
  const angle = reverse ? 180 : 0;

  return <Gradient angle={angle}/>
}


const Gradient = styled.div`
  height: 100px;
  width: 100%;
  background: linear-gradient(${p => p.angle}deg, ${COLORS.background}, ${COLORS.primary});

  @media ${QUERIES.tabletAndDown} {
    height: 50px;
  }
`;

export default Glow;
import styled from "styled-components/macro";
import { COLORS, QUERIES } from "../../constants";
import ServiceBlock from "../ServiceBlock";
import { serviceData } from "../../services-data";
import MaxWidthWrapper from "../MaxWidthWrapper";

const ServicesSection = () => {
  return (
    <SectionWrapper>
      <MaxWidthWrapper>
        <SectionTitle>services</SectionTitle>
        <InnerWrapper>
          {serviceData.map((service, i) => {
            return (
              <ServiceBlock
                title={service.title}
                text={service.text}
                imgURL={service.imageURL}
                key={i}
              />
            );
          })}
        </InnerWrapper>
      </MaxWidthWrapper>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section``;
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px 0px;

  @media ${QUERIES.tabletAndDown} {
    gap: 24px;
  }

`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  color: ${COLORS.text};
  //margin-top: 32px;
  margin-bottom: 32px;
  user-select: none;

  @media ${QUERIES.tabletAndDown} {
    margin-bottom: 24px;
  }
`;

export default ServicesSection;

import styled from 'styled-components/macro';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
//import MaxWidthWrapper from '../../components/MaxWidthWrapper';
import ServicesSection from '../../components/ServicesSection';
import Glow from "../../components/Glow";

const ServicesPage = () => {
  return (
    <Wrapper>
      <Header/>
      <Glow />
      <ServicesSection/>
      <Footer />
    </Wrapper>

  )
}

const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`

export default ServicesPage;
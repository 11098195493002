import styled from "styled-components/macro";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Hero from "./components/Hero";
import ServicesSection from "../../components/ServicesSection";
import MaxWidthWrapper from "../../components/MaxWidthWrapper";
import Glow from "../../components/Glow";
import { COLORS } from "../../constants";

const LandingPage = () => {
  return (
    <div>
      <TopPageWrapper>
        <Header backgroundColor={COLORS.background} />
        <Hero />
        <Glow reverse/>
      </TopPageWrapper>
      <Glow />
      <ServicesSection />
      <Footer />
    </div>
  );
};

const TopPageWrapper = styled.main`
  //min-height: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export default LandingPage;

import styled from "styled-components/macro";
import { keyframes } from "styled-components";
import { COLORS, WEIGHTS } from "../../constants";
import { X } from "react-feather";
import ReactFocusLock from "react-focus-lock";
import { RemoveScroll } from "react-remove-scroll";

const Drawer = ({ children, handleDismiss }) => {
  return (
    <ReactFocusLock>
      <RemoveScroll>
        <Wrapper>
          <Backdrop />
          <Foreground>
            <ItemsWrapper>
              {children}
            </ItemsWrapper>
            <DismissButton onClick={handleDismiss}>
              <X />
            </DismissButton>
          </Foreground>
        </Wrapper>
      </RemoveScroll>
    </ReactFocusLock>
  );
};

const slideIn = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  padding: 16px;
`;

const Backdrop = styled.div`
  position: absolute;
  inset: 0;
  background: hsl(350deg 100% 30% / 0.75);
  backdrop-filter: blur(3px);
  animation: ${fadeIn} 850ms cubic-bezier(0.14, 0.78, 0.36, 1);
`;

const Foreground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  max-width: 300px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${COLORS.background};
  color: ${COLORS.primary};
  padding: 32px;
  animation: ${slideIn} 500ms cubic-bezier(0.14, 0.78, 0.36, 1);
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-height: 60%;
  margin-block-start: auto;
  margin-block-end: auto;
`

const DismissButton = styled.button`
  padding: 8px 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 1.2rem;
  font-weight: ${WEIGHTS.bold};
  color: ${COLORS.text};
  margin-left: auto;
  margin-right: auto;

  position: fixed;
  top: 8px;
  right: 8px;
`;

export default Drawer;

import styled from "styled-components/macro";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import MaxWidthWrapper from "../../components/MaxWidthWrapper";
import MainStory from "./components/MainStory";
import SideStory from "./components/SideStory";
import Glow from "../../components/Glow";
import { useState } from 'react';

import { newsData } from "../../news-data";
import { COLORS, QUERIES } from "../../constants";

const NewsPage = () => {
  const [selectedStory, setSelectedStory] = useState(newsData[0]);
  const subStories = newsData.filter(story => story.headline !== selectedStory.headline);

  return (
    <div>
      <Header />
      <Glow />
      <ContentWrapper>
        {/* <Title>news</Title> */}
        <Layout>
          <MainStory story={selectedStory} />
          <SideStories>
            {subStories.map(story => {
              return (
                <SideStory key={story.headline} story={story} selectStory={() => setSelectedStory(story)}/>
              )
            })}
          </SideStories>
        </Layout>
      </ContentWrapper>
      <Footer />
    </div>
  );
};

// const Wrapper = styled.div`
//   min-height: 100%;
//   height: 0;
//   /* border: 1px solid red; */
//   display: flex;
//   flex-direction: column;
// `;

// const LayoutGrid = styled.main`
//   display: grid;

// `


const ContentWrapper = styled(MaxWidthWrapper)`
  padding-top: 32px;

  @media(${QUERIES.tabletAndDown}) {
    padding-top: 0;
  }
`;
const Layout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px 64px;

  @media ${QUERIES.tabletAndDown} {
    flex-direction: column;
  }

`;
// const Title = styled.h1`
//   font-size: 2.5rem;
//   color: ${COLORS.text};
//   border-bottom: 2px solid ${COLORS.primary};
//   margin-bottom: 16px;
// `;
const SideStories = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 0px;
  padding: 0;
  border-top: revert;

  @media ${QUERIES.tabletAndDown} {
    margin-top: 16px;
    padding: 32px 0;
    border-top: 2px solid ${COLORS.primary};
    border-bottom: 2px solid ${COLORS.primary};
  }
`

export default NewsPage;

import styled from 'styled-components/macro';
import MaxWidthWrapper from '../../../../components/MaxWidthWrapper';
import { COLORS, QUERIES, WEIGHTS } from '../../../../constants';
import heroImage from '../../../../assets/images/bettasplendens-cropped.jpg';

const Hero = () => {

  return (
    <Wrapper>
      <HeroContainer>
        <TextSection>
          {/* <HeroTitle>lexagon</HeroTitle> */}
          <HeroText>business legal services <br/> done differently</HeroText>
        </TextSection>
        <ImageSection>
           <HeroImage src={heroImage} />
        </ImageSection>
      </HeroContainer>
    </Wrapper>
  )
}

const Wrapper = styled(MaxWidthWrapper)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //height: calc(100vh - 200px);
  //height: calc(100vh);
  flex-grow: 1;
  background-color: ${COLORS.background};
`
const HeroContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TextSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-basis: 50%;
  flex-grow: 1;
`
const HeroTitle = styled.h2`
  color: ${COLORS.primary};
  font-weight: ${WEIGHTS.bold};
  font-size: 4rem;
  line-height: 1.4;

  @media ${QUERIES.tabletAndDown} {
    font-size: 3rem;
  }
`
const HeroText = styled.p`
  //Inverse video
  color: white;
  font-weight: ${WEIGHTS.bold};
  font-size: 3.0rem;
  line-height: 1.2;

  @media ${QUERIES.tabletAndDown} {
    font-size: 2.5rem;
  }
`

const ImageSection = styled.div`
  //width: 100%;
  height: 80%;
  flex-basis: 30%;
  flex-grow: 0;
`

const HeroImage = styled.img`
  object-fit: contain;
  object-position: right;
  width: 100%;
  height: 0;
  min-height: 100%;
`

export default Hero;
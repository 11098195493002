import { useState } from "react";
import styled from "styled-components/macro";
import { Menu } from "react-feather";
import Drawer from "../Drawer";
import NavLink from "../NavLink";
import { COLORS } from "../../constants";
import { useLocation } from "react-router-dom";

const MobileMenu = () => {
  const [isDrawerShowing, setIsDrawerShowing] = useState(false);
  const location = useLocation();
  const currentPage = location.pathname;

  return (
    <div>
      <StyledIcon>
        <Menu onClick={() => setIsDrawerShowing(true)} />
        {isDrawerShowing && (
          <Drawer handleDismiss={() => setIsDrawerShowing(false)}>
            <NavLink to={`/`} isCurrent={currentPage === "/"}>
              home
            </NavLink>
            <NavLink to={`/services`} isCurrent={currentPage === "/services"}>
              services
            </NavLink>
            <NavLink to={`/news`} isCurrent={currentPage === "/news"}>
              news
            </NavLink>
            <NavLink to={`/about`} isCurrent={currentPage === "/about"}>
              about
            </NavLink>
          </Drawer>
        )}
      </StyledIcon>
    </div>
  );
};

const StyledIcon = styled.div`
  color: ${COLORS.primary};
`;

export default MobileMenu;

import styled from "styled-components/macro";
import MaxWidthWrapper from "../MaxWidthWrapper";
import { COLORS, QUERIES } from "../../constants";

const Footer = () => {
  return (
    <Wrapper>
      <MaxWidthWrapper>
        <InnerWrapper>
          <Block>
            {/* <Typography>terms of use</Typography>
            <Typography>privacy policy</Typography> */}
            <Typography>&copy; lexagon 2022 - 2023</Typography>
          </Block>
          <Block>
            <Typography>info@lexagon.co.uk</Typography>
            <Typography>71-75 Shelton Street</Typography>
            <Typography>Covent Garden</Typography>
            <Typography>London WC2H 9JQ</Typography>
          </Block>
        </InnerWrapper>
      </MaxWidthWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  margin-top: 32px;
  background-color: ${COLORS.background};
  padding-top: 64px;
  padding-bottom: 32px;
  opacity: 0.7;
`;

const InnerWrapper = styled.div`
  min-height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const Block = styled.div``;

const Typography = styled.p`
  color: ${COLORS.text};
  font-size: 1rem;
`;

export default Footer;

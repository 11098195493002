import styled from 'styled-components/macro';
import { Link } from "react-router-dom";
import { COLORS, WEIGHTS} from '../../constants';

const NavLink = ({to, isCurrent, children}) => {
  return (
    <StyledLink to={to} $current={isCurrent}>
      {children}
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  font-size: 1.5rem;
  font-weight: ${WEIGHTS.bold};
  color: ${(p) => (p.$current === true ? COLORS.text : COLORS.primary)};
  transition: color 150ms ease-in;

  :hover {
    color: ${COLORS.text};
  }
`;

export default NavLink;
import styled from 'styled-components/macro';
import { COLORS } from '../../../../constants';

const SideStory = ( {story, selectStory} ) => {

  return (
    <Wrapper onClick={selectStory}>
      <Headline>{story.headline}</Headline>
      <div style={{overflow: 'hidden'}}>

      <Summary>{story.summary}</Summary>
      </div>
      {/* <Image src={`http://localhost:3000/images/${story.imageName}`}/> */}
      <Image src={`/images/${story.imageName}`}/>
    </Wrapper>
  )
}

// const Wrapper = styled.div`
//   flex: 0;
//   display: grid;
//   grid-template-columns: 120px 1fr;
//   //grid-template-rows: 0fr 1fr;
//   align-content: flex-start;
//   grid-template-areas:  "image headline"
//                         "image summary";
//   column-gap: 8px;
//   row-gap: 0px;
//   padding-bottom: 16px;

//   :not(:last-of-type) {
//     border-bottom: 1px solid ${COLORS.primary};
//   }
// `

// const Headline = styled.h3`
//   grid-area: headline;
//   font-size: 1.2rem;
//   color: ${COLORS.primary};
//   line-height: 1.2;
// `
// const Summary = styled.p`
//   grid-area: summary;
//   color: ${COLORS.text};
//   max-width: 140ch;
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   -webkit-line-clamp: 3;
//   /* Necessary for line-clamping */
//   overflow: hidden;
//   align-self: flex-start;
// `
// const Image = styled.img`
//   grid-area: image;
//   display: block;
//   //width: 120px;
//   //height: 100%;
//   object-fit: cover;

//   width: 100%;
//   height: 120px;
// `


////////////////////////////////////////////////////////////


const Wrapper = styled.div`
  flex: 0;
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-template-rows: auto 1fr;
  align-content: flex-start;
  grid-template-areas:  "image headline"
                        "image summary";
  column-gap: 24px;
  row-gap: 0px;
  //padding-bottom: 16px;
  height: 120px;

  :hover {
    cursor: pointer;
  }
  
`
const Headline = styled.h3`
  grid-area: headline;
  font-size: 1.2rem;
  color: ${COLORS.text};
  line-height: 1.2;
  user-select: none;
`
const Summary = styled.p`
  grid-area: summary;
  color: ${COLORS.text};
  max-width: 140ch;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* Necessary for line-clamping */
  overflow: hidden;
  align-self: flex-start;
`
const Image = styled.img`
  grid-area: image;
  display: block;
  //width: 120px;
  //height: 100%;
  object-fit: cover;

  width: 100%;
  height: 120px;
`

export default SideStory;
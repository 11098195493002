import styled from "styled-components/macro";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { COLORS, WEIGHTS } from "../../constants";
import MaxWidthWrapper from "../../components/MaxWidthWrapper";
import Glow from "../../components/Glow";

const AboutPage = () => {
  return (
    <div>
      <Header />
      <Glow />
      <MaxWidthWrapper>
        <Title>about us</Title>

        <Text>
          Our mission is to deliver first class legal services to SMEs without all the red tape, delay and expense of traditional law firms.
        </Text>

        <SubTitle>what we do</SubTitle>
        <Text>We offer expertise across all areas of law typically relevant to SMEs, such as</Text>
        <ul>
          <BulletPoint>drafting and reviewing commercial contracts to manage risk</BulletPoint>
          <BulletPoint>ensuring compliance with data protection and other legislation</BulletPoint>
          <BulletPoint>advising on employment, recruitment and staffing issues</BulletPoint>
        </ul>
        <Text>Check out our services page for a more detailed description of how we can help your business.</Text>

        <SubTitle>how we do it</SubTitle>
        <ul>
        <BulletPoint>we bring modern practices and agile working practices to commercial legal services.</BulletPoint>
        <BulletPoint>we focus on key areas of commercial law, such as contracts and compliance</BulletPoint>
        <BulletPoint>our experienced, freelance lawyers are focussed on delivering value to our clients</BulletPoint>
        <BulletPoint>we keep overheads to a minimum – no expensive offices, client entertainment or highly paid partners</BulletPoint>
        </ul>
       
       <SubTitle>what it means for you</SubTitle>
       <ul>
         <BulletPoint>pragmatic, commercially astute legal advice</BulletPoint>
         <BulletPoint>high quality legal services at a fair price</BulletPoint>
         <BulletPoint>quick turnaround times</BulletPoint>
         <BulletPoint>fixed price quotes</BulletPoint>
       </ul>
      </MaxWidthWrapper>
      <Footer />
    </div>
  );
};

const Title = styled.h2`
  color: ${COLORS.text};
  font-size: 3rem;
  font-weight: ${WEIGHTS.bold};
  margin-bottom: 16px;
  user-select: none;
`;
const SubTitle = styled.h3`
  color: ${COLORS.text};
  font-size: 2rem;
  font-weight: ${WEIGHTS.bold};
  margin-bottom: 8px;
  margin-top: 48px;
  user-select: none;
`;
const Text = styled.p`
  color: ${COLORS.text};
  font-size: 1.25rem;
  user-select: none;
`;
const BulletPoint = styled.li`
  color: ${COLORS.text};
  font-size: 1.25rem;
  list-style: disc;
  list-style-position: inside;
  padding-left: 16px;
  user-select: none;
`

export default AboutPage;

import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { COLORS, QUERIES, WEIGHTS } from "../../constants";
import { useLocation } from "react-router-dom";

const NavBar = () => {
  const location = useLocation();
  const currentPage = location.pathname;

  return (
    <Wrapper>
      <NavLink to={`/`} $current={currentPage === "/"}>
        home
      </NavLink>
      <NavLink to={`/services`} $current={currentPage === "/services"}>
        services
      </NavLink>
      <NavLink to={`/news`} $current={currentPage === "/news"}>
        news
      </NavLink>
      <NavLink to={`/about`} $current={currentPage === "/about"}>
        about
      </NavLink>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  display: flex;
  gap: 24px;

`;

const NavLink = styled(Link)`
  font-size: 1.5rem;
  font-weight: ${WEIGHTS.bold};
  color: ${(p) => (p.$current === true ? COLORS.text : COLORS.primary)};
  transition: color 150ms ease-in;
  user-select: none;

  :hover {
    color: ${COLORS.text};
  }
`;

export default NavBar;

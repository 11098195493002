export const COLORS = {
  background: 'hsla(0, 0%, 0%, 1)',
  text: 'hsla(360, 100%, 100%, 1)',
  gray: {
    100: 'hsl(40deg 12% 95%)',
    200: 'hsl(37.5deg 10% 87.5%)',
    300: 'hsl(35deg 8% 80%)',
    500: 'hsl(30deg 4% 60%)',
    700: 'hsl(28deg 5% 40%)',
    900: 'hsl(24deg 6% 16%)',
  },
  //primary: 'hsl(356deg 100% 36%)',
  primary: 'hsl(0deg 100% 34%)',
  secondary: 'hsl(345deg 94% 49%)',
  urgent: 'hsl(0deg 55% 54%)',
};

export const WEIGHTS = {
  light: 300,
  normal: 500,
  bold: 800,
}

export const FAMILIES = {
  serif: '"Crimson Pro", Georgia, serif',
  sansSerif:
    '"Raleway", Helvetica, "Franklin Gothic Medium", "Franklin Gothic", "ITC Franklin Gothic", sans-serif',
  cursive: ' "Alkatra", "Akronim", "Berkshire Swash", "Lemon", cursive',
  logo: 'Lemon',
}

export const BREAKPOINTS = {
  tabletMin: 550,
  laptopMin: 1100,
  desktopMin: 1500,
  mobileMax: 600,
  tabletMax: 950,
  laptopMax: 1300,
}

export const QUERIES = {
  tabletAndDown: `(max-width: ${BREAKPOINTS.tabletMax / 16}rem)`,
  laptopAndDown: `(max-width: ${BREAKPOINTS.laptopMax / 16}rem)`,
  desktopAndDown: `(max-width: ${BREAKPOINTS.desktopMax / 16}rem)`,
  tabletAndUp: `(min-width: ${BREAKPOINTS.tabletMin / 16}rem)`,
  laptopAndUp: `(min-width: ${BREAKPOINTS.laptopMin / 16}rem)`,
  desktopAndUp: `(min-width: ${BREAKPOINTS.desktopMin / 16}rem)`,
}


import styled from "styled-components/macro";
import { COLORS, QUERIES } from "../../constants";
import decoration from '../../assets/images/fins01.svg';

const ServiceBlock = ({ imgURL, title, text }) => {
  return (
    <Wrapper>
      <FishWrapper>
          <img src={decoration} alt='' style={{height: '100%', display: 'grid', placeItems: 'center', marginTop: '0px', stroke: 'red'}}/>
      </FishWrapper>
      <ServiceDetails>
        <ServiceTitle>{title}</ServiceTitle>
        <ServiceText>{text}</ServiceText>
      </ServiceDetails>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px 0px;

  @media ${QUERIES.laptopAndUp} {
    flex-direction: row;
    gap: 0px 20px;
    justify-content: flex-start;

    &:nth-of-type(2n) {
      align-self: flex-end;
    }
    &:not(:last-of-type) {
      margin-bottom: 32px;
    }
  }
`;

const ServiceDetails = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px 0px;
  color: white;
  user-select: none;
  transition: all 500ms;

  @media ${QUERIES.laptopAndUp} {
    flex-basis: 80%;
    padding-left: 24px;
  }
`;

const FishWrapper = styled.div`
  height: 120px;
  width: 120px;
  color: ${COLORS.primary};
  transform: translateY(0.2rem);

  @media(${QUERIES.tabletAndDown}) {
    height: 2.5rem;
  }
`;

const ServiceTitle = styled.h3`
  font-size: 2rem;
  color: ${COLORS.primary};
  text-align: left;
  user-select: none;

  @media ${QUERIES.tabletAndDown} {
    font-size: 1.75rem;
    line-height: 1.1;
  }
`;

const ServiceText = styled.p`
  max-width: 50ch;
  font-size: 1.125rem;
  padding-bottom: 0.75rem;
  color: ${COLORS.text};
`;

export default ServiceBlock;

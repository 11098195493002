import styled from "styled-components/macro";
import MaxWidthWrapper from "../MaxWidthWrapper";
import { COLORS, QUERIES, WEIGHTS } from "../../constants";
import NavBar from "../NavBar";
import MobileMenu from "../MobileMenu";
import logoImage from '../../assets/images/ff.svg';

const Header = ({ backgroundColor }) => {
  const bgColor = backgroundColor || COLORS.background;
  return (
    <Wrapper bgColor={bgColor}>
      <Row>
        <FishWrapper>
          <img
            src={logoImage}
            style={{
              height: "100%",
              display: "grid",
              placeItems: "center",
              marginTop: "0px",
              stroke: "red",
            }}
            alt='fighting fish'
          />
        </FishWrapper>
        <TextWrapper>
          <HeaderText>lexagon</HeaderText>
          <DesktopNav>
            <NavBar />
          </DesktopNav>
          <MobileNav>
            <MobileMenu />
          </MobileNav>
        </TextWrapper>
      </Row>
    </Wrapper>
  );
};

const DesktopNav = styled.div`
  @media ${QUERIES.tabletAndDown} {
    display: none;
  }
`;
const MobileNav = styled.div`
  display: none;
  align-self: baseline;
  @media ${QUERIES.tabletAndDown} {
    display: revert;
  }
`;
const Wrapper = styled.header`
  width: 100%;
  //flex-basis: 100px;
  //flex-grow: 0;
  background-color: ${(p) => p.bgColor};
  //padding: 16px 0;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-grow: 1;
`;

const Row = styled(MaxWidthWrapper)`
  //height: 80px;
  height: 100%;
  width: 100%;
  display: flex;
  //align-items: baseline;
  align-items: center;
  //justify-content: space-between;
  justify-content: flex-start;
  //gap: 16px;
  padding-top: 8px;
  padding-bottom: 8px;

  @media (${QUERIES.tabletAndDown}) {
    //gap: 8px;
  }
`;

const FishWrapper = styled.div`
  //height: 80%;
  height: 5rem;
  color: ${COLORS.primary};
  transform: translateY(0.2rem);

  @media (${QUERIES.tabletAndDown}) {
    height: 2.5rem;
  }
`;

const HeaderText = styled.h1`
  color: ${COLORS.primary};
  font-size: 4rem;
  font-weight: ${WEIGHTS.bold};
  user-select: none;

  @media (${QUERIES.tabletAndDown}) {
    font-size: 3rem;
  }
`;

export default Header;

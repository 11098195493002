export const newsData = [
  {
    imageName: 'hawk.jpg',
    date: 'March 2023',
    headline: `minimum wage increases`,
    summary: `From 1 April 2023. both the National Minimum Wage and National Living Wage are increasing.`,
    content: `Workers aged 23 or over are entitled to National Living Wage at £10.42 an hour.

    Workers under 23 are entitled to National Minimum Wage at the following hourly rates:
    
      - aged 21 or 22      £10.18 per hour
      - aged 18 - 20        £7.49 per hour
      - under 18            £5.28 per hour

      Apprentices aged under 19 and/or in the first year of their apprenticeship are entitled to be paid £5.28 per hour.

      Apprentices aged 19 and above, or who have completed the first year of their apprenticeship are entitled to be paid at the applicable National Minimum Wage rate for their age.
      
      All businesses should ensure that their employees, temp workers and apprentices are paid at least the applicable hourly rate.
      
      Staffing businesses should also ensure that they update Key Information Documents, if these use the National Minimum Wage as the example pay rate, to ensure full transparency and compliance with the National Minimum Wage legislation
    `,
  },
  {
    imageName: "hawk.jpg",
    date: "July 2023",
    headline: "dealing with DSARs",
    summary: "",
    content: `Data subject access requests (DSARs) from former employees and candidates are an increasingly common occurrence for businesses, as we all become more aware of our data protection rights.

    A candidate who doesn’t get the job may want to know why and may ask to see references, emails or internal notes containing their personal data. A disgruntled former employee may make a DSAR to find evidence for an Employment Tribunal claim or to make themselves a nuisance.
      
    The Information Commission’s Office (ICO) updated guidance on dealing with DSARs helps you through the process step by step.
    
    There are some key issues of particular relevance to employers and recruiters:
    · The right to access is a necessarily a right to receive the documents in which the personal data is contained.

    · Controllers do not have to disclose personal data if it would infringe the personal data rights of third parties (including clients and other employees or candidates).

    · Third party personal data can be redacted or, in some circumstances, withheld, if it would identify a third party.

    · An exemption to the right of access under the Data Protection Act 2018 means that employers and recruiters are not obliged to disclose references which are given in confidence.

    · Commercially sensitive management forecasting and management planning information may also be exempt in some circumstances.

    · If a former employee or candidate makes multiple DSARs, you may be able to charge a fee or even refuse to respond if the requests are ‘manifestly excessive’.

    · Controllers do not have to disclose every email sent or copied to a former employee, if it does not contain other personal data; it is sufficient to identify the number of such emails.

    · The updated guidance contains information about dealing with ‘mixed data’

    For further information or general advice on DSARs, you can visit the ICO website, or contact us for advice on a specific issue.
    `
  },
  {
    imageName: "ppe.jpg",
    date: "May 2023",
    headline: "employment law reforms",
    summary: "",
    content: `A policy paper issued by the government set out the proposals for the government’s plans to reform employment law, following the UK’s departure from the EU.

    The paper, Smarter Regulation to Grow the Economy, was published on 10 May 2023, and contains proposals to:
    · allow the payment of ‘rolled-up’ holiday pay
    · abolish employers’ obligation to record all workers’ and employees’ working time
    · merge EU &UK statutory annual leave entitlements
    · limit the duration of non-competition clauses in contracts of employment

    The follow-up Consultation Paper on Retained EU Employment Law was published on 12 May 2023.

    Rolled-up holiday pay
    The payment of rolled-up holiday pay is to be permitted under the new proposals. Rolled-up holiday pay is in most cases prohibited under EU case law, which states that workers should receive their payment of holiday pay at the time the worker actually takes holiday.

    In practice, however, rolled-up holiday pay is commonly used by employers and staffing agencies when paying employees and temporary workers in casual or short term arrangements. Under this arrangement, an employer or agency pays a worker their annual leave entitlement as a percentage on top of their hourly rate (usually an additional 12.07%).

    If rolled-up holiday pay is permitted going forwards, it is likely that employers and agency will need to ensure that documentation such as contracts, pay slips and Key Information Documents (KIDs) set out, in a clear and comprehensible manner, how holiday pay is calculated and paid.

    Working time recording
    The government has suggested that employers will save £1 billion pounds a year from the removal of the obligation (under EU case law) to record the time worked by all employees and workers. The extent to which the relevant EU case law applies in the UK is unclear, and it is still less clear how many UK employers currently comply with it.

    In practice, employers of low-skilled employees and workers will still have to record working time to comply with their obligations under the national minimum wage legislation.

    Merging of annual leave entitlement
    The government proposes to merge employees’ and workers’ 4 weeks’ annual leave entitlement under EU law, with their additional 1.6 weeks’ entitlement under UK legislation, so that there is a single annual leave entitlement of 5.6 weeks (in each case, based on full time employment).

    Non-competition provisions
    The government’s rationale for limiting the duration of non-compete clauses to 3 months is to allow employees greater scope to join their former employer’s competitors or to set up in business themselves, increasing competition in the market place and widening the taken pool.

    However, the move is unlikely to be welcomed by employers who have invested heavily in training employees, particularly in STEM fields, only to see their investment and know-how being exploited by rival businesses a few months later.

    There are no proposals to limit other forms of post-termination restrictions, such as non-solicit and non-deal provisions, and in practice some employees will still effectively be prohibited from working for competitors of their former employers by these restrictions, even if the non-compete is limited to three months. Employers, however, may argue that breaches of non-solicit and non-deal provisions are much easier to conceal that breaches of a non-compete provisions, and so offer former employers only limited protection.

    There will be a mixed reaction from employers and agencies to the raft of proposals, although they fall some way short of the ‘bonfire’ of EU employment laws previously mooted by the government.

    `
  },
]

import GlobalStyles from "./components/GlobalStyles";
import styled from 'styled-components/macro';
import LandingPage from "./pages/LandingPage";
import NewsPage from "./pages/NewsPage";
import ServicesPage from "./pages/ServicesPage/ServicesPage";
import AboutPage from "./pages/AboutPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
//import MaxWidthWrapper from "./components/common/MaxWidthWrapper";

const router = createBrowserRouter([
  {
    path: "",
    element: <LandingPage />,
  },
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "news",
    element: <NewsPage />,
  },
  {
    path: "services",
    element: <ServicesPage />,
  },
  {
    path: "about",
    element: <AboutPage />,
  },
  { 
    path:'*',
    element: <LandingPage />,
  },
]);

function App() {
  return (
    <Wrapper>
      <RouterProvider router={router} />
      <GlobalStyles />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
`

export default App;

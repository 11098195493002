export const serviceData = [
  {
    title: 'contracts & risk',
    summary: `We draft all types of commercial contracts, for use with clients,
    customers, suppliers, business partners, employees, workers and
    contractors.`,
    text: `We draft all types of commercial contracts, for use with clients,
    customers, suppliers, business partners, employees, workers and
    contractors. 

    We can also review agreements and advise on risk and liabilities.`,
    imageURL: 'water-splashing.jpg'
  },
  {
    title: 'regulatory compliance',
    summary: `Businesses are becoming increasingly regulated. Failure to comply
    with relevant legislation can result in lost fees, fines, litigation
    and even criminal liability.`,
    text: `Businesses are becoming increasingly regulated. Failure to comply
    with relevant legislation can result in lost fees, fines, litigation
    and even criminal liability. 

    We can help you see the wood from the trees.`,
    imageURL: 'birch-trees.jpg'
  },
  {
    title: 'data protection',
    summary: `Safeguarding the personal data of your clients, employees and
    candidates has never been so important.`,
    text: `Safeguarding the personal data of your clients, employees and
    candidates has never been so important.

    Whether you need DPO services or specific advice on responding to
    access requests, let us help you to avoid data breaches, fines and
    reputational damage`,
    imageURL: 'clouds.jpg'
  },
  {
    title: 'it law',
    summary: 'We can draft, amend and advise upon all types of commercial IT agreements, from SaaS agreements, licensing agreements & NDAs.',
    text: 'We can draft, amend and advise upon all types of commercial IT agreements, from SaaS agreements, licensing agreements & NDAs.',
    imageURL: 'web-partial.jpg'
  },
  {
    title: 'ir35 & tax compliance',
    summary: `We help businesses comply with the ever-changing tax landscape when engaging contractors & self-employed individuals.`,
    text: `We help businesses comply with the ever-changing tax landscape when engaging contractors & self-employed individuals.

    We can provide robust terms and review determinations to minimise the risk of investigations, liability & penalties.`,
    imageURL: 'vultures.jpg',
  },
  {
    title: 'employment law',
    summary: `Whether you’re hiring or firing, we can help.`,
    text: `Whether you’re hiring or firing, we can help. Having the right employment contracts, policies and procedures in place will help to protect your confidential information & minimise the risk of time-consuming & costly tribunal claims.`,
    imageURL: 'eye.jpg',
  },
  {
    title: 'fee recovery & disputes',
    summary: `We can help you to recover fees from difficult clients without the need for litigation.`,
    text: `We can help you to recover fees from difficult clients without the need for litigation. 
         
          We can also assist with many other types of disputes.`,
    imageURL: 'fighting-fish.jpg',
  },
  {
    title: 'general compliance',
    text: `We can advise on all aspects of general compliance, from modern slavery to the Criminal Finances Act 2017.`,
    imageURL: 'owl.jpg',
  },
];

